import React from "react"
import {connect} from "react-redux"
import OrderFormStep2ShowData from "../../components/OrderFormStep2ShowData";
import {
    setOrderProcessTotalPrice,
    setProductsShows,
    setProductsToCartDetails,
    setProductsLengths,
    setItemsToDelete, setCutProducts
} from "../OrderFormStep1/actions";
import {
    FIELDS_CODE, getCustomArtProduct, getFieldsByProductSku,
    getPkgArtProductsName, getProductsWithOneShow,
    getShowRequiredFieldsByProductSku, getTvAndRadioProductsType
} from "../../actions/Constants";
import OrderFormStep2SharedFields from "../../components/OrderFormStep2SharedFields";
import OrderProcessNavigation from "../../components/OrderProcessNavigation";
import {
    getAdmatFilesFromCategoryInfo,
    getAllCutsFromSelectedProducts,
    getCutFromSelectedProducts, getFieldsToShowOnVenueSection,
    getTotalPrice, numberFormat
} from "../../helpers";
import UpdateCartItemProduct from "../../components/UpdateCartItemButton";
import LoadingSmall from "../../components/LoadingSmall";

const mapStateToProps = state => ({
    ...state.orderProcess,
    ...state.productsToCart,
})

const mapDispatchToProps = {
    setProductsShows,
    setProductsToCartDetails,
    setOrderProcessTotalPrice,
    setProductsLengths,
    setItemsToDelete,
    setCutProducts
};

class OrderFormStep2 extends React.Component{
    constructor(props){
        super(props);
        this.showInformation = {
            [FIELDS_CODE.show_date]: '',
            [FIELDS_CODE.show_time]: '',
            [FIELDS_CODE.special_guests]: '',
            [FIELDS_CODE.venue_capacity.code]: '',
            [FIELDS_CODE.venue_name]: '',
            [FIELDS_CODE.ticket_locations]: '',
            [FIELDS_CODE.city_market_name]: '',
            [FIELDS_CODE.mentions.sku.mention_reserved_seats]: false,
            [FIELDS_CODE.mentions.sku.mention_general_admission]: false,
            [FIELDS_CODE.mentions.code]: {}
        };
        this.fieldsToShow = [];
        this.state = {
            shows: props.productsToCartShows? props.productsToCartShows:
                [{...this.showInformation}],

            sharedDetails: props.productsToCartSharedDetails? props.productsToCartSharedDetails:{
                [FIELDS_CODE.special_instructions]: '',
                [FIELDS_CODE.intro]: '',
                [FIELDS_CODE.logos]: '',
                [FIELDS_CODE.outro]: '',
                [FIELDS_CODE.logo_file.code]: '',
                [FIELDS_CODE.logo_file_1.code]: '',
                [FIELDS_CODE.logo_file_2.code]: '',
                [FIELDS_CODE.logo_file_3.code]: '',
                [FIELDS_CODE.logo_file_4.code]: '',
                [FIELDS_CODE.logo_file_5.code]: '',
                [FIELDS_CODE.logo_file_6.code]: '',
                [FIELDS_CODE.logo_file_7.code]: '',
                [FIELDS_CODE.logo_file_8.code]: '',
                [FIELDS_CODE.logo_file_9.code]: '',
                [FIELDS_CODE.station_id]: '',
                [FIELDS_CODE.include_station_id_mention.sku.include_station_id_mention_in_tv]: false,
                [FIELDS_CODE.include_station_id_mention.code]: {},
                [FIELDS_CODE.generic_radio_check.code]: {},

            },

            totalPrice: this.props.orderTotalPrice,
            errors: {},
            requiredFields: [],
            showFields: [],
            pkgArtForm: false,
            showSharedDetailsForm: false,
        };

        this.addShow = this.addShow.bind(this);
        this.removeShow = this.removeShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.updateShow = this.updateShow.bind(this);
        this.updateSharedInformation = this.updateSharedInformation.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.showStep3 = this.showStep3.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
    }
    getRequiredFiles() {
        let selectedProducts = this.props.selectedProducts;
        let requiredFields = [];
        for(let sProductsIndex in selectedProducts) {
            let selectedProduct = selectedProducts[sProductsIndex];
            requiredFields = [...requiredFields, ...getShowRequiredFieldsByProductSku(selectedProduct.sku)]
        }
        return requiredFields.filter((item, index) => requiredFields.indexOf(item) == index);
    }
    componentDidMount() {
        console.log("component did mount");
        let totalPrice = this.getTotalPrice();
        this.setState({
            totalPrice: totalPrice
        })
        this.setState({
            requiredFields: this.getRequiredFiles(),
            pkgArtForm: this.productPkgArtForm(),
            showSharedDetailsForm: this.showSharedDetailsSection(),
        })
        this.getFieldsToShow();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.productsToCartShows !== this.props.productsToCartShows) {
            this.setState({
                shows: this.props.shows,
            })
        }
        if(prevProps.productsToCartSharedDetails !== this.props.productsToCartSharedDetails) {
            this.setState({
                sharedDetails: this.props.productsToCartSharedDetails,
            })
        }
        if((prevState.shows !== this.state.shows) ||
            prevState.sharedDetails !== this.state.sharedDetails) {
            this.setState({
                totalPrice: this.getTotalPrice(),
            })
        }
        if(prevState.totalPrice !== this.state.totalPrice) {
            this.props.setOrderProcessTotalPrice(this.state.totalPrice);
        }
        if(prevProps.selectedProducts !== this.props.selectedProducts) {
            this.setState({
                requiredFields: this.getRequiredFiles(),
                pkgArtForm: this.productPkgArtForm(),
            })
            this.getFieldsToShow();
        }
    }
    getFieldsToShow() {
        let selectedProducts = this.props.selectedProducts;
        let allFields = getFieldsToShowOnVenueSection(selectedProducts);
        this.fieldsToShow = allFields;
        return allFields
    }

    resetLengthIfVenueChange(thisShows) {
        if(thisShows.length > 0) {
            let thisShow = thisShows[0];

            let allCutProducts = getAllCutsFromSelectedProducts(thisShow[FIELDS_CODE.venue_capacity.code], this.props.selectedProducts);

            let productsToCartLengths = this.props.productsToCartLengths;
            let itemsToDelete = [...this.props.itemsToDelete];
            if(productsToCartLengths) {
                for (let lengthIndex in productsToCartLengths) {
                    let productLength = productsToCartLengths[lengthIndex];
                    for (let productLengthIndex in productLength){
                        let lengthData = productLength[productLengthIndex];
                        for(let dataIndex in lengthData) {
                            let data = lengthData[dataIndex];
                            if(data.item_id && itemsToDelete.indexOf(data.item_id) === -1) {
                                itemsToDelete.push(data.item_id);
                            }
                        }
                    }
                }
                if(this.props.itemsToDelete !== itemsToDelete) {
                    this.props.setItemsToDelete(itemsToDelete);
                }
            }
            this.props.setProductsLengths(null);
            // this.props.setFirstCutProduct(firstCutProducts)
            // this.props.setAdditionalCutProduct(additionCutProducts)
            this.props.setCutProducts(allCutProducts)

        }

    }
    addShow()
    {
        let shows = [...this.state.shows];
        shows.push({...this.showInformation});
        this.setState({
            shows: shows,
        })
    }
    updateShow(showindex, showInformation)
    {
        let shows = [...this.state.shows];
        shows[showindex] = showInformation;
        if(showindex == 0) {
            console.log('shows[showindex]', shows[showindex])
            console.log('this.state.shows', this.state.shows[showindex])
            if(showInformation.venue_capacity !== this.state.shows[showindex]['venue_capacity'] ) {
                this.resetLengthIfVenueChange(shows);
            }
        }
        this.setState({
            shows: shows
        })
    }
    removeShow(event)
    {
        console.log(event);
        let {shows} = this.state;
        let index = event.target.getAttribute("showindex");
        shows.splice(index, 1);
        this.setState({
            shows: [...shows],
        })
    }

    updateSharedInformation(sharedDetails)
    {
        this.setState({
            sharedDetails: {...sharedDetails}
        })
    }
    formIsValid()
    {
        let required = this.state.requiredFields;
        let errors = {};
        let shows = this.state.shows;
        let showsErrors = [];

        Object.keys(shows).map((showIndex) => {
            let show = shows[showIndex];
            required.map((item) => {
                if(show[item] == '') {
                   if(showsErrors[showIndex]) {
                       showsErrors[showIndex].push(item)
                   }
                   else {
                       showsErrors[showIndex] = [item]
                   }
                }
            });
        });
        let sharedDetails = this.state.sharedDetails;
        let sharedDetailsErrors = [];
        required.map((item) => {
            console.log(sharedDetails[item]);
            console.log(item);
            if(item in sharedDetails && sharedDetails[item] == '') {
                sharedDetailsErrors.push(item)
            }
        });
        if(Object.keys(showsErrors).length > 0) {
            errors["shows"] = showsErrors;
        }
        if(Object.keys(sharedDetailsErrors).length > 0) {
            errors['sharedDetails'] = sharedDetailsErrors;
        }
        this.setState({
            errors: errors
        });
        return errors

    }

    handleSubmit(event)
    {
        event.preventDefault();

        console.log('Logo', this.state.sharedDetails);
        let errors = this.formIsValid();
        let isValid = true;
        if(Object.keys(errors).length > 0)
        {
            setTimeout(() => {
                window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
            }, 100)

            isValid = false;
        }
        if(isValid) {
            this.props.nextStep({shows :this.state.shows, details: this.state.sharedDetails});
        }
    }

    goBack()
    {
        this.props.setProductsShows(this.state.shows);
        this.props.setProductsToCartDetails(this.state.sharedDetails);
        this.props.goBack()
    }

    getTotalPrice() {
        if(this.props.selectedProducts && this.state.shows
            && this.state.sharedDetails
        ) {
            let options = [this.state.sharedDetails];
            let totalPrice = getTotalPrice(this.props.selectedProducts, options, this.state.shows);
            return totalPrice;
        }
        return 0;
    }

    showSharedDetailsSection() {
        let selectedProducts = this.props.selectedProducts;
        let productsType = getTvAndRadioProductsType();
        let showForm = false;

        for(let sProductsIndex in selectedProducts) {
            let selectedProduct = selectedProducts[sProductsIndex];
            let selectedProductType = selectedProduct.extension_attributes.product_type.id;
            console.log('Products selectedProductType', selectedProductType);
            if(productsType.indexOf(selectedProductType) >= 0) {
                showForm =  true;
            }
            console.log('Products selectedProductType', showForm);
        }
        return showForm;

    }

    productPkgArtForm()
    {
        let selectedProducts = this.props.selectedProducts;
        let pkgProductsName = getPkgArtProductsName();
        for(let sProductsIndex in selectedProducts) {
            let selectedProduct = selectedProducts[sProductsIndex];
            if(selectedProduct.sku.toLowerCase() !== getCustomArtProduct() && pkgProductsName.indexOf(selectedProduct.sku.toLowerCase()) == -1) {
                return false;
            }
        }
        return true
    }
    showStep3() {
        let selectedProducts = this.props.selectedProducts;
        let showStep3 = false;
        let sharedFields = [];
        let fieldCodes = FIELDS_CODE;
        for(let codeIndex in fieldCodes) {
            let code = fieldCodes[codeIndex];
            if(typeof code === "string" ) {
                sharedFields.push(code)
            }
            if(typeof code === "object") {
                sharedFields.push(code.code);
            }
        }
        console.log('Shared fields options', )
        for(let sProductsIndex in selectedProducts) {
            let selectedProduct = selectedProducts[sProductsIndex];
/*            let extensionAttributes = selectedProduct.extension_attributes;
            if(extensionAttributes && extensionAttributes.product_type && extensionAttributes.product_type.id != 'art') {
                showStep3 = true
            }*/
            let options = selectedProduct.options;
            for (let optionIndex in options) {
                let option = options[optionIndex];
                if(option.extension_attributes) {
                    let optionCode = option.extension_attributes.option_code;
                    if(sharedFields.indexOf(optionCode) === -1) {
                        showStep3 = true;
                        console.log('option shared', optionCode)
                    }
                }
            }
        }

        return showStep3;
    }
    getAdmatFiles () {
        let admatFiles = getAdmatFilesFromCategoryInfo(this.props.categoryInfo, this.props.selectedProducts);
        return admatFiles;
    }

    displayAddShowVenue() {
        let productsOneShow = getProductsWithOneShow();
        let selectedProducts = this.props.selectedProducts;
        for(let index in selectedProducts) {
            let selectedProduct = selectedProducts[index];
            console.log('displayAddShowVenue', selectedProduct.sku, selectedProduct);
            if(productsOneShow.indexOf(selectedProduct.sku.toLowerCase()) === -1) {
                return true
            }
        }
        return false;
    }

    render(){
        let {shows, sharedDetails, errors, pkgArtForm, showSharedDetailsForm} = {...this.state};
        let {categoryInfo, categoryId, cart, productsToCart, selectedProducts, itemsToDelete, isFetchingAddProductToCart} = this.props;
        let count = this.state.shows.length - 1;
        let totalPrice = this.state.totalPrice;
        let showFields = this.state.showFields;
        let admatFiles = this.getAdmatFiles();
        if(!categoryInfo) {
            categoryInfo = {};
        }

        if(isFetchingAddProductToCart) {
            return (
                <main id="main" className="black" role="main"
                      style={{backgroundImage: `url(${categoryInfo.category_background_image})`}}>
                    <div id="content" className="container fullscreen-checkout">
                        <div id="order-form" className="step-two row">
                            <LoadingSmall
                                label="Updating cart"
                            />
                        </div>
                    </div>
                </main>
            )
        }

        return (
            <div>
                <OrderProcessNavigation
                    categoryInfo={categoryInfo}
                    totalPrice={totalPrice}
                    step="step2"
                    showStep3={this.showStep3()}
                                    />
                <main id="main" className="black" role="main"
                      style={{backgroundImage: `url(${categoryInfo.category_background_image})`, backgroundColor: 'black'}}
                >
                    <div id="content" className="container fullscreen-checkout">
                        <div id="order-form" className="step-two row">
                            <div className="col-xs-12">
                                <form onSubmit={this.handleSubmit}>
                                    {errors && errors.shows && errors.shows.length > 0
                                    && <div className="alert error">
                                        <p>Please complete the fields outlined in red below before proceeding.</p>
                                    </div>}

                                    {pkgArtForm && <div className="order-message order-message-border-bottom mb-40">
                                        {(selectedProducts && Object.keys(selectedProducts).length > 0
                                          && selectedProducts[Object.keys(selectedProducts)[0]].sku.toLowerCase() === 'customizable social templates')?
                                          <p>This information is for billing only. Your layered social templates will be ready for you to localize for your needs.</p>
                                          : <p>This information is for billing only. Your print files will be ready for you to size and localize for your needs.</p>
                                        }

                                        <ul className="file-list mt-20">
                                            {admatFiles.map((name, index) => {
                                                return <li key={index}>
                                                    {name.admat_file}
                                                </li>
                                            })}
                                        </ul>
                                    </div>}
                                    <small className="required">Required</small>
                                    {Object.keys(shows).map((index) =>
                                        <div key={index}>
                                            <OrderFormStep2ShowData
                                                show={shows[index]} showindex={index}
                                                updateShow = {this.updateShow}
                                                errors={errors.shows? errors.shows[index]:[]}
                                                pkgArtForm={pkgArtForm}
                                                fieldsToShow={this.fieldsToShow}
                                            />
                                            <button className="remove-show button xsmall red-white" type={'button'} onClick={this.removeShow} showindex={index}
                                                    disabled={!count}>
                                                Remove Show Above
                                            </button>
                                        </div>
                                    )}
                                    {this.displayAddShowVenue() && <div className="row">
                                        <div className="col-xs-12">
                                            <div className="repeat-control">
                                                <button type="button" onClick={this.addShow}>SHOW/VENUE</button>
                                            </div>
                                        </div>
                                    </div>}

                                    <OrderFormStep2SharedFields
                                        sharedDetails={sharedDetails}
                                        updateSharedInformation={this.updateSharedInformation}
                                        errors={errors.sharedDetails? errors.sharedDetails:[]}
                                        selectedProducts={selectedProducts}
                                    />
                                    <div id="order-total" className="order-total">
                                        <dl>
                                            <dt>Order Estimate:</dt>
                                            <dd>{numberFormat(totalPrice)}</dd>
                                        </dl>
                                    </div>
                                    <div className="order-options" id="order-options-bottom">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <button type="button" className="button medium white-white" onClick={this.goBack}>
                                                    Back
                                                </button>
                                            </div>
                                            {this.showStep3() && <div className="col-sm-3 col-sm-offset-6">
                                                <button type="submit" className="button medium blue-white"
                                                    id="nextStepButton"
                                                >Next</button>
                                            </div>}
                                            {!this.showStep3() &&
                                            <UpdateCartItemProduct
                                                productsToCart={productsToCart}
                                                productsToCartShows={[...this.state.shows]}
                                                productsToCartSharedDetails={{...this.state.sharedDetails}}
                                                productsToCartCustomOptions={null}
                                                productsLengths={{}}
                                                selectedProducts={selectedProducts}
                                                saveLengthsInfo={this.saveLengthsInfo}
                                                categoryId={categoryId}
                                                categoryInfo={categoryInfo}
                                                productsToCartShipping={{}}
                                                itemsToDelete={itemsToDelete}
                                                cartInfo = {cart}
                                                comeFrom={this.props.comeFrom}
                                                isValidForm={this.formIsValid}
                                                errors={errors}
                                            />
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormStep2)

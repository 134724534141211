import React from "react"
import {connect} from "react-redux"
import {FIELDS_CODE} from "../../actions/Constants";
import {custom_options} from "../Product/ONLINE_ART/definition";

class FileBase64 extends React.Component{
    constructor(props) {
        super(props);
        this.style = {
            width: '0.1px',
            height: '0.1px',
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        }
        this.state = {
            fileName: this.props.fileName?this.props.fileName:'',
            allowedExtensions: this.getAllowedExtensions()
        }
    }
    getAllowedExtensions() {
        let allowedExtensions = this.props.allowedExtensions;
        if(Array.isArray(allowedExtensions)) {
            let allowedExtensionsStr = allowedExtensions.join(', .');
            if(allowedExtensions.length > 0) {
                allowedExtensionsStr = '.' + allowedExtensionsStr;
            }
            return allowedExtensionsStr;
        }
        return allowedExtensions;
    }
    componentDidMount() {
        let data = this.props.data;
        let fileName =  this.extractNameFromData(data);
        this.setState({
            fileName: fileName
        })
    }
    extractNameFromData(data) {
        let fileName = '';
        if(data) {
            if(typeof data == "string") {
                console.log('data', data)
                let dataFile = data.split(',');
                if(dataFile.length > 2) {
                    fileName = dataFile[1];
                }
            }
            else {
                if(data.name) {
                    fileName = data.name;
                }
            }
        }
        return fileName;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data != this.props.data) {
            let data = this.props.data;
            let fileName =  this.extractNameFromData(data);
            this.setState({
                fileName: fileName
            })
        }
        if(prevProps.allowedExtensions != this.props.allowedExtensions) {
            this.setState({
                allowedExtensions: this.getAllowedExtensions()
            })
        }
    }

    handleChange(e) {
        let files = e.target.files;
        if (files.length > 0) {
            let file = files[0];
            console.log('file', file);
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                let result = reader.result;
                if(reader.result) {
                    console.log(reader.result);
                    let str = `data:${file.type};base64,`;
                    if(reader.result.includes(str)) {
                        result = reader.result.split(str)[1];
                    }
                }
                let fileName = file.name;
                //replace not allowed characters in name.
                const regex = /[?*:";<>()\|{}\/]/g;
                fileName = fileName.replace(regex, '_');
                fileName = fileName.replace('-', '_');
                console.log('fileName', fileName)
                // Make a fileInfo Object
                let fileInfo = {
                    name: fileName,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64_encoded_data: result,
                    file: file,
                };
                let isValidExt = this.getExtensionFromFileInfo(fileInfo)
                if(isValidExt) {
                    this.setState({
                        fileName: fileName
                    })
                    let feedBackInfo = this.props.feedBackInfo;
                    this.props.onDone(fileInfo, feedBackInfo)
                } else  {
                    alert('The selected file extension is not valid.');
                }
            }
        }
    }

    getExtensionFromFileInfo(fileInfo) {
        if(fileInfo) {
            let extensionAllowed = this.state.allowedExtensions;
            let name = fileInfo.name;
            let splitName = name.split('.');
            let ext = '.' + splitName[splitName.length - 1];
            console.log('getExtensionFromFileInfo',ext, extensionAllowed )
            if(extensionAllowed.indexOf(ext) !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }

    render(){
        let fileName = this.state.fileName;
        let id = this.props.id;
        let feedBackInfo = this.props.feedBackInfo;
        let optionsDescription = this.props.optionsDescription;
        let allowedExtensions = this.state.allowedExtensions;
        return (
            <div-file>
                <input
                    id={id?id:'file'}
                    type="file"
                    style={this.style}
                    className={this.props.clasname}
                    onChange={ this.handleChange.bind(this) }
                    multiple={ false }
                    accept={allowedExtensions}
                />
                {fileName !== "" &&
                    <small>File selected: {fileName}</small>
                }
                {fileName == "" &&
                    <small>No File Selected</small>
                }
                {feedBackInfo.code && optionsDescription && feedBackInfo.code in optionsDescription &&
                <small>{optionsDescription[feedBackInfo.code]}</small>
                }
            </div-file>
        )
    }
}

export default connect(null, null)(FileBase64)

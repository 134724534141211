import React from "react"
import {connect} from "react-redux";
import {FIELDS_CODE} from "../../actions/Constants";
import CustomCheckbox from "../CustomCheckbox";
import FileBase64 from "../FileBase64"
import {getOptionAllowedExtensionByCode} from "../../helpers";

class OrderFormStep2SharedFields extends React.Component{
    constructor(props) {
        super(props);

        console.log('sharedDetails',this.props.sharedDetails);
        let propsSharedDetails = this.props.sharedDetails
        let uploadLogos = [];
        for(let i = 0; i < 10; i++) {
            let codeEnd = '';
            if(i !== 0) {
                codeEnd = `_${i}`;
            }
            if(propsSharedDetails['logo_file' + codeEnd]) {
                uploadLogos.push(null);
            }
        }
        this.state = {
            ...propsSharedDetails,
            errors: [...this.props.errors],
            allowed_logo_extensions: [],
            upload_logos: uploadLogos
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckAndRadio = this.handleChangeCheckAndRadio.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.addUploadLogo = this.addUploadLogo.bind(this);
        this.checkIfExistsOptionInProducts = this.checkIfExistsOptionInProducts.bind(this);
        this.getAllowedExtensions = this.getAllowedExtensions.bind(this);
    }

    componentDidMount() {
        if(this.props.selectedProducts) {
            this.setState({
                allowed_logo_extensions: this.getAllowedExtensions()
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.sharedDetails != this.props.sharedDetails) {
            this.setState({
                errors: this.state.errors,
                ...this.props.sharedDetails
            })
        }
        if(prevProps.errors != this.props.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
        if(prevProps.selectedProducts != this.props.selectedProducts) {
            this.setState({
                allowed_logo_extensions: this.getAllowedExtensions()
            });
        }
    }

    getAllowedExtensions() {
        let selectedProducts = this.props.selectedProducts;
        let allowedExtensions = [];
        let firstTime = true;
        for(let index in selectedProducts) {
            let product = selectedProducts[index];

            let productLogoExtension =  getOptionAllowedExtensionByCode(product, FIELDS_CODE.logo_file.code)
            if(productLogoExtension.length === 0) {
                continue;
            }
            if(firstTime) {
                allowedExtensions = [...productLogoExtension]
            } else {
                allowedExtensions = allowedExtensions.filter(x => productLogoExtension.includes(x));
            }
            firstTime = false;
        }
        return allowedExtensions;
    }

    handleChange(event)
    {
        let state = {...this.state};
        let code = event.target.getAttribute("code");
        if(event.target.type == 'checkbox')
        {
            state[code] = !state[code];
            let fieldCode = event.target.getAttribute('field-code');
            state[fieldCode][code] = state[code];
            console.log("is checkbox", state[code]);
        }
        else {
            state[code] = event.target.value;
        }
        console.log(code);
        this.setState(state);
        this.props.updateSharedInformation({...state});
    }
    handleChangeCheckAndRadio(info) {
        let state = this.state;
        let code = info.code
        if(!info.type || info.type == 'checkbox') {
            state[code] = !state[code];
            let fieldCode = info['field-code'];
            state[fieldCode][code] = state[code];
        }
        else {
            state[code] = info.value;
        }
        console.log("state", state);
        this.setState(state);
        this.props.updateSharedInformation(state);
    }
    uploadFile(fileInfo, index)
    {
        let code = FIELDS_CODE.logo_file.code;
        if(index && index !== 0) {
            code = `${code}_${index}`
        }
        this.setState({
            [code]: fileInfo
        }, () => {
            this.props.updateSharedInformation({...this.state});
        })
    }
    addUploadLogo() {
        let logos = this.state.upload_logos;
        if (logos?.length < 10) {
            logos.push(null);
            this.setState({
                upload_logos: logos
            })
        }
    }
    deleteFile(index) {
        let code = FIELDS_CODE.logo_file.code;

        let logos = this.state.upload_logos;
        let temp = {};
        for (let i = index; i <= logos.length; i++) {
            if(i === 0) {
                temp[FIELDS_CODE.logo_file.code] = this.state[FIELDS_CODE.logo_file.code + `_${i + 1}`];
            } else {
                temp[FIELDS_CODE.logo_file.code + `_${i}`] = this.state[FIELDS_CODE.logo_file.code + `_${i + 1}`];
            }

        }

        logos.pop();
        this.setState({
            ...temp
            // upload_logos: logos
        }, () => {this.props.updateSharedInformation({...this.state});})
    }
    checkIfExistsOptionInProducts(optionCode) {
        let selectedProducts = this.props.selectedProducts
        if(selectedProducts) {
            for (let productId in selectedProducts) {
                let product = selectedProducts[productId];
                let options = product.options;
                if(options) {
                    for(let optionIndex in options) {
                        let option = options[optionIndex];
                        let extensionAttributes = option.extension_attributes;
                        if(extensionAttributes) {
                            if(optionCode == extensionAttributes.option_code) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    renderIntro() {
        let state = {...this.state};
        if(this.checkIfExistsOptionInProducts(FIELDS_CODE.intro)) {
            return <>
                    <label>Intro</label>
                    <input type='text'
                           name="intro"
                           value={state[FIELDS_CODE.intro]}
                           code = {FIELDS_CODE.intro}
                           onChange={this.handleChange}
                    />
                    <small>(Presented by, Produced by, Sponsored by, etc.)</small>
                </>
        }
        return false;
    }
    renderOutro() {
        let state = {...this.state};
        if(this.checkIfExistsOptionInProducts(FIELDS_CODE.outro)) {
            return <>
                    <label>Outro</label>
                    <input type="text"
                           name="outro"
                           value={state[FIELDS_CODE.outro]}
                           code = {FIELDS_CODE.outro}
                           onChange={this.handleChange}
                    />
                    <small>(Presented by, Produced by, Sponsored by, etc.)</small>
                </>
        }
        return false;
    }

    renderStationTvCheck() {
        let state = {...this.state};
        if(this.checkIfExistsOptionInProducts(FIELDS_CODE.include_station_id_mention.code) ){
            return <li>
                <CustomCheckbox
                  checked={state[FIELDS_CODE.include_station_id_mention.sku.include_station_id_mention_in_tv]}
                  label='Include Station ID Mention in TV?'
                  type="checkbox"
                  name={"include_station_id_mention_in_tv"}
                  onchange={this.handleChangeCheckAndRadio}
                  feedBackInfo={{
                      value: 1,
                      code: FIELDS_CODE.include_station_id_mention.sku.include_station_id_mention_in_tv,
                      'field-code': FIELDS_CODE.include_station_id_mention.code
                  }}
                />
            </li>
        }
    }
    renderGenericRadioCheck() {
        let state = {...this.state};
        if(this.checkIfExistsOptionInProducts(FIELDS_CODE.generic_radio_check.code) ){
            return <li>
                  <CustomCheckbox
                    checked={state[FIELDS_CODE.generic_radio_check.sku.additional_generic_radio]}
                    label='I will need generic radio in addition to my Station ID'
                    type="checkbox"
                    name={"additional_generic_radio"}
                    onchange={this.handleChangeCheckAndRadio}
                    feedBackInfo={{
                        value: 1,
                        code: FIELDS_CODE.generic_radio_check.sku.additional_generic_radio,
                        'field-code': FIELDS_CODE.generic_radio_check.code
                    }}
                  />
              </li>
        }
    }

    renderStationId() {
        let state = {...this.state};
        if(this.checkIfExistsOptionInProducts(FIELDS_CODE.station_id)) {
            return <>
                <label>Station ID</label>
                <input type="text"
                       name="station_id"
                       value={state[FIELDS_CODE.station_id]}
                       code={FIELDS_CODE.station_id}
                       onChange={this.handleChange}
                />
                <ul>
                    {this.renderStationTvCheck()}
                    {this.renderGenericRadioCheck()}
                </ul>
            </>
        }
    }

    renderUploadLogo() {
        let uploadFiles = this.state.upload_logos;
        let logos = [];
        let state = this.state;
        logos.push(uploadFiles.map((value, index, array)  => {
            return <div className="file-upload mt-10"
                        key={index}
            >
                <label htmlFor={index?`logo_file_${index}`:'logo_file'} className={'button xxsmall blue-white'}>
                    Select File
                </label>
                <FileBase64
                  key={index}
                  onDone={(info) => {
                      this.uploadFile(info, index)
                  }}
                  id={index?`logo_file_${index}`:'logo_file'}
                  data={state[FIELDS_CODE.logo_file.code + (index?`_${index}`:'')]}
                  allowedExtensions={this.state.allowed_logo_extensions}
                  clasname={''}
                  feedBackInfo={
                      {
                          code: index?'logo_file' + `_${index}`:'logo_file'
                      }
                  }
                />
                <button
                  className="remove-logo button xxsmall red-white"
                  type="button"
                  onClick={() => {
                      this.deleteFile(index)
                  }}
                >
                    Remove
                </button>
            </div>
        }))
        return logos;
    }

    render() {
        let state = {...this.state};
        let errors = [];
        console.log(state)
        if (state.errors) {
            errors = [...state.errors];
        }
        let renderIntro = this.renderIntro();
        let renderOutro = this.renderOutro();
        let renderStationId = this.renderStationId();
        return (
          <div>
              <div className="form-section">
              {this.checkIfExistsOptionInProducts(FIELDS_CODE.special_instructions) && <div className="row">
                      <div className="form-group col-sm-12">
                          <label className="">Special Instructions</label>
                          <textarea name="especial_instructions"
                                    className={(errors && errors.indexOf(FIELDS_CODE.special_instructions) != -1) ? 'error' : ''}
                                    value={state[FIELDS_CODE.special_instructions]}
                                    code={FIELDS_CODE.special_instructions}
                                    onChange={this.handleChange}
                          ></textarea>
                      </div>
                  </div>}
                  <div className="row">
                      {(renderIntro || renderOutro || renderStationId) &&
                        <div className="form-group col-sm-6">
                            {renderIntro}
                            {renderOutro}
                            {renderStationId}

                        </div>}
                        {this.checkIfExistsOptionInProducts(FIELDS_CODE.logo_file.code) &&
                          <div className="form-group col-sm-6">

                              {this.checkIfExistsOptionInProducts(FIELDS_CODE.logos) && <>
                                  <label>Logos (for Video Only)</label>
                                  <input type="text"
                                         name="logos"
                                         value={state[FIELDS_CODE.logos]}
                                         code={FIELDS_CODE.logos}
                                         onChange={this.handleChange}
                                  />
                              </>
                              }

                              <label className="mt-40">Upload Logo</label>
                              {this.renderUploadLogo()}
                              <div className='row'>
                                  <div className="col-xs-12">
                                      <div className="repeat-control-parent">
                                          <div className="repeat-control repeat-control-inner">
                                              <button
                                                className={''}
                                                type="button"
                                                onClick={() => {
                                                    this.addUploadLogo()
                                                }}
                                                disabled={false}
                                              >
                                                  Add File
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(OrderFormStep2SharedFields)
